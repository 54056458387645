.authHeaderContainer {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 30px 19.5%;
  background-color: white;
  position: relative;
  z-index: 300;
  &__black-logo img {
    width: 240px;
  }

  &__authButtons {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    gap: 20px;
    &__backButton {
      margin-right: 20px;
      button {
        background-color: transparent !important;
        color: $color-grey-2400 !important;
        font-size: 12px;
        font-weight: 600;
        display: flex;
        align-items: center;
        &__backButton {
          margin-right: 20px;
          button {
            background-color: transparent !important;
            color: $color-grey-2400 !important;
            font-size: 12px;
            font-weight: 600;
            display: flex;
            align-items: center;
            &:hover {
              cursor: pointer;
              color: $color-blue-200 !important;
              transition: 200ms;
            }
          }
          .icon-arrow-left {
            font-size: 18px;
          }
        }
      }
      .icon-arrow-left {
        font-size: 18px;
      }
    }
    &__languageButton {
      a {
        color: $color-black-true;
        padding: 0;
        font-weight: 500;
        &:hover {
          cursor: pointer;
          color: $color-blue-200;
        }
      }
      .icon-arrow-left {
        font-size: 18px;
      }
    }
    &__businessButton {
      border-color: $color-blue-200 !important;
      color: $color-blue-200 !important;
      background-color: transparent;
      font-weight: 600;
      padding: 9px 21px;
    }
    &__language-dropdown {
      img {
        margin-right: 6px;
        font-size: $fs-14;
        font-weight: 500;
      }
      .popover-wrapper {
        right: 20%;
        top: 124px;
        width: 298px;
      }
    }
  }
  .language-switcher {
    .icon-arrow-bottom::before,
    .icon-arrow-top::before {
      color: $color-grey-3000;
    }
    .popover-wrapper {
      top: 86px;
    }
  }
}

.mobile-view {
  display: none;
}
body[dir='rtl'] {
  .authHeaderContainer {
    &__authButtons {
      &__backButton {
        margin-left: 20px;
        margin-right: 0px;
        .icon-arrow-right {
          font-size: 18px;
        }
      }

      &__languageButton {
        .dropdown-item {
          margin-right: 0px;
        }
      }
      .language-switcher_wrapper_img {
        margin-left: 0;
      }
    }
  }
}

@media only screen and (min-width: 913px) and (max-width: 1280px) {
  .authHeaderContainer {
    padding: 30px 12%;
    &__authButtons__language-dropdown {
      .popover-wrapper {
        right: 13%;
        top: 124px;
      }
    }
  }
}

@media only screen and (max-width: 912px) {
  .web-view {
    display: none !important;
  }
  .mobile-view {
    display: block;
  }

  .authHeaderContainer {
    display: flex;
    padding: 20px 15px;
    align-items: center;
    &__upper-header {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 20px;

      &__mobile-back-button {
        span {
          background-color: transparent;
          font-size: 24px;
          color: $color-grey-2400 !important;
        }
      }
      &__languageButton {
        .nav-item {
          .nav-link {
            padding: 0%;
            color: $color-black-true;
          }
        }
      }
      &__black-logo img {
        width: 165px;
      }
    }

    &__authButtons {
      flex-wrap: unset;
      button {
        padding: 0;
        background-color: transparent;
        font-size: $fs-12;
        font-weight: 600;
        color: $color-primary;
        height: 25px;
        border: unset;
      }
      &__language-dropdown {
        .popover-wrapper {
          right: 20px;
          top: 104px;
        }
      }
    }
  }
}

@media only screen and (max-width: 560px) {
  .authHeaderContainer {
    align-items: flex-start;
    &__black-logo {
      img {
        width: 110px;
      }
    }
    &__authButtons__language-dropdown {
      .popover-wrapper {
        top: 75px;
        &::before {
          right: 1rem;
        }
      }
    }
  }
}

@media only screen and (max-width: 321px) {
  body[dir='rtl'] {
    .cardProducts-overlay {
      width: 500px;
      right: -100px;
    }
  }
}
