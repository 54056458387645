.languages-dropdown {
  padding: 24px;
  &_title {
    font-size: $fs-16;
    font-weight: 600;
  }
  &_divider {
    margin: 24px 0;
    color: $color-grey-4600;
    opacity: 1;
  }

  &_item {
    padding: 12px 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid $color-grey-4600;
    cursor: pointer;
    &:last-child {
      border-bottom: none;
    }
    &_wrapper {
      &_img {
        margin-right: 12px;
      }
      &_text {
        color: $color-grey-3900;
        font-size: $fs-14;
        font-weight: 500;
      }
    }
  }
  &_button {
    margin-top: 24px;
    .button {
      width: 100%;
      margin-left: unset;
    }
  }
}
